import React, { useState } from 'react';
import Layout from "../components/layout"
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import projects from '../projects';

const ProjectPage = ({ data, pageContext }) => {
  const [project] = projects.filter(p => p.id === pageContext.slug);
  const images = data.allFile.nodes.reduce((acc, node) => {
    acc[node.name] = getImage(node.childImageSharp);
    return acc;
  });
  return (
    <Layout>
      <p>← <Link to={'/'} className="link">Retour</Link></p>
      <div className="project">
        <h1>{project.title}</h1>
        <p className="mono subtitle">{project.client}</p>
        <GatsbyImage
          image={images[project.id]}
          alt={project.title}
          quality={90}
          className="project-image"
        />
        <div className="project-content">
          <div className="project-data">
            {false && <div className="field">
              <div className="mono">{project.client}</div>
            </div>}
            <div className="field">
              <div className="label">Date</div>
              <div className="mono">{project.date}</div>
            </div>
            <div className="field">
              <div className="label">Rôle</div>
              <div className="mono">{project.role}</div>
            </div>
          </div>
          <div className="project-description">
            <p>{project.text}</p>
            {project.link && <p className="mono">↗ {' '}<a className="link" target="_blank" href={project.link}>Voir le site</a></p>}
          </div>
        </div>
      </div>
      {project.slides && false && Array.from(Array(project.slides)).map((val, i) => (
        <div className="folio">
          <GatsbyImage
            image={images[project.id + '-' + i]}
            alt={project.title}
            quality={90}
          />
        </div>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

export default ProjectPage
